<template lang="pug">
.brand-wrapper
  .container.connect-shop
    .row
      .col-8.mx-auto
        .d-flex.justify-content-center
          img.waving-monk(:src="require('@/assets/waving.png')")
        .h4.text-center.my-4 {{ $t('connect.hi', { firstName: firstName }) }}!
    .row.pt-1
      .col-6.mx-auto
        .text-center {{ $t('connect.welcomeToOptiMonk') }}
    .row
      .col-6.mx-auto
        .text-center {{ $t('connect.whatToDo') }}
    .row.pt-4.mt-4
      .col-8.col-lg-8.mx-auto
        .d-flex.justify-content-center.flex-wrap
          button.brand-btn.brand-btn-primary.brand-btn-primary-reverse.brand-btn-lg.mx-4.my-2(
            type="submit"
            @click.prevent="newAccount()"
          )
            i.fas.fa-plus-circle.mr-2
            span.text-dark {{ $t('connect.newAccount') }}
          button.brand-btn.brand-btn-primary.brand-btn-primary-reverse.brand-btn-lg.mx-4.my-2(
            type="submit"
            @click.prevent="existingLogin()"
          )
            i.fas.fa-key.mr-2
            span.text-dark {{ $t('connect.existingLogin') }}
</template>

<script>
  import LOGOUT from '@/graphql/Logout.gql';
  import GET_SHOP_USER_HASH from '@/graphql/GetShopUserHash.gql';
  import CREATE_ACCOUNT_FOR_SHOP from '@/graphql/CreateAccountForShop.gql';
  import { mapMutations } from 'vuex';
  import FINALIZE_SHOPIFY_INSTALL from '@/graphql/FinalizeShopifyInstall.gql';
  import CREATE_LOGIN_CREDENTIALS from '@/graphql/CreateLoginCredentials.gql';
  import { track, getHeap } from '@/services/xray';
  import { setAccountIdInCookie } from '@/util';

  export default {
    data() {
      return {
        firstName: '',
        shop: null,
      };
    },
    async created() {
      this.shop = this.$route.params.shop;
      this.$store.commit('setLocale', this.shop === 'shopify' ? 'en' : 'hu');

      const {
        data: { getShopUserHash: resp },
      } = await this.$apollo.query({
        query: GET_SHOP_USER_HASH,
        variables: {
          type: this.shop,
        },
      });

      if (!resp) {
        const locale = this.shop === 'shopify' ? 'en' : 'hu';
        return this.$router.replace({ name: 'login', params: { lang: locale } });
      }
      this.firstName = resp.firstName || 'there';
    },
    methods: {
      ...mapMutations(['showAdminLoader']),
      async newAccount() {
        this.showAdminLoader(true);
        track('connectShop-newAccount', this.shop);
        this.$apollo
          .mutate({
            mutation: CREATE_ACCOUNT_FOR_SHOP,
            variables: {
              type: this.shop,
            },
          })
          .then(
            async ({
              data: {
                createAccountForShop: {
                  success,
                  isNewRegistration,
                  myshopifyDomain,
                  isAgency,
                  agencyDatabaseId,
                },
              },
            }) => {
              if (success) {
                const locale = this.shop === 'shopify' ? 'en' : 'hu';
                let installSuccess = true;

                if (this.shop === 'shopify' && isNewRegistration) {
                  await this.$apollo
                    .query({
                      query: FINALIZE_SHOPIFY_INSTALL,
                    })
                    .then(({ data: finalizeSuccess }) => {
                      installSuccess = finalizeSuccess;
                    });
                } else {
                  this.$router.push({ name: 'login', params: { lang: locale } });
                }
                this.$router.push({
                  name: 'login',
                  params: { lang: locale },
                  query: {
                    confirm_shopify_install: installSuccess ? 'success' : 'failed',
                    domain: myshopifyDomain,
                  },
                });
              } else if (isAgency && this.shop === 'shopify') {
                const { data } = await this.$apollo.mutate({
                  mutation: CREATE_LOGIN_CREDENTIALS,
                  variables: { databaseId: agencyDatabaseId },
                });

                const login = data.login;
                const userId = login.dataAccountId;
                setAccountIdInCookie(userId);
                getHeap?.()?.identify?.(userId);
                this.$store.dispatch('login', {
                  expiration: login.expiration,
                  accountType: login.accountType,
                  locale: login.locale,
                });
                this.$router.push({
                  name: 'connect_shopify',
                });
              } else {
                this.showAdminLoader(false);
                this.$notify({
                  type: 'error',
                  text: 'Error during account creation. Please contact our support team!',
                });
              }
            },
          );
      },
      async existingLogin() {
        this.showAdminLoader(true);
        track('connectShop-login', this.shop);
        await this.$apollo.mutate({
          mutation: LOGOUT,
        });
        this.showAdminLoader(false);
        this.$store.commit('logout');
      },
    },
  };
</script>
